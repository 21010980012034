exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-banks-js": () => import("./../../../src/pages/banks.js" /* webpackChunkName: "component---src-pages-banks-js" */),
  "component---src-pages-connect-bank-tsx": () => import("./../../../src/pages/connect-bank.tsx" /* webpackChunkName: "component---src-pages-connect-bank-tsx" */),
  "component---src-pages-connect-tsx": () => import("./../../../src/pages/connect.tsx" /* webpackChunkName: "component---src-pages-connect-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-own-connections-tsx": () => import("./../../../src/pages/own-connections.tsx" /* webpackChunkName: "component---src-pages-own-connections-tsx" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-subscribe-tsx": () => import("./../../../src/pages/subscribe.tsx" /* webpackChunkName: "component---src-pages-subscribe-tsx" */),
  "component---src-pages-subscribed-tsx": () => import("./../../../src/pages/subscribed.tsx" /* webpackChunkName: "component---src-pages-subscribed-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

